import React from "react"
import {
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  DrawerBody,
  Flex,
  HStack,
  useDisclosure,
  Stack,
  Box,
} from "@chakra-ui/react"
import { HiMenu } from "react-icons/hi"

import Link from "./Link"
import { LogoWhiteX, LogoColourX } from "./Logos"
import Section from "./Section"

interface NavProps {
  nav_1_copy: string
  nav_2_copy: string
  invertedNav?: boolean
}
const Nav = ({ invertedNav, nav_1_copy, nav_2_copy }: NavProps) => {
  return (
    <HStack
      spacing={8}
      as="nav"
      display={["none", "flex"]}
      flexGrow={1}
      justifyContent="flex-end"
      sx={{
        a: {
          color: invertedNav ? "primary" : "white",
          fontWeight: "bold",
          borderBottom: "2px solid transparent",
          "&:hover": {
            borderBottom: "2px solid white",
          },
        },
      }}
    >
      <Link to="/sailing-tips">{nav_1_copy}</Link>
      <Link to="/get-inspired">{nav_2_copy}</Link>
    </HStack>
  )
}

interface MobileNavProps {
  nav_1_copy: string
  nav_2_copy: string
  invertedNav?: boolean
}

const MobileNav = ({ nav_1_copy, nav_2_copy, invertedNav }: MobileNavProps) => {
  const navProps = useDisclosure()
  return (
    <>
      <IconButton
        display={["inline", "none"]}
        variant="unstyled"
        fill={invertedNav ? "primary" : "white"}
        aria-label="Menu Handle"
        onClick={navProps.onOpen}
        icon={<Box as={HiMenu} boxSize="24px" />}
      />
      <Drawer placement="right" {...navProps}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody mt={16}>
              <Stack direction="column">
                <Link to="/sailing-tips">{nav_1_copy}</Link>
                <Link to="/get-inspired">{nav_2_copy}</Link>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

interface HeaderProps {
  copy: { nav_1: string; nav_2: string }
  className?: string
  invertedNav?: boolean
}

export const Header = ({ className, copy: { nav_1, nav_2 }, invertedNav }: HeaderProps) => {
  const logo = invertedNav ? (
    <LogoColourX h={["30px", "48px", "48px"]} w={["73px", "117px", "117px"]} />
  ) : (
    <LogoWhiteX h={["30px", "48px", "48px"]} w={["73px", "117px", "117px"]} />
  )

  return (
    <Section
      as="header"
      id="search"
      w="100%"
      zIndex="docked"
      backgroundColor="transparent"
      position={invertedNav ? "sticky" : "absolute"}
      className={className}
    >
      <Flex align="center" justify="space-between" py={6}>
        <Link lineHeight={0} to="/" sx={{ height: ["20px", "48px", "48px"] }}>
          {logo}
        </Link>
        <Nav invertedNav={invertedNav} nav_1_copy={nav_1} nav_2_copy={nav_2} />
        <MobileNav nav_1_copy={nav_1} nav_2_copy={nav_2} invertedNav={invertedNav} />
      </Flex>
    </Section>
  )
}

export default Header
