import React from "react"
import { Center, chakra, Heading } from "@chakra-ui/react"
import { GatsbyImage, getImage, ImageDataLike, StaticImage } from "gatsby-plugin-image"

import Section from "./Section"
import { HeroTabs } from "./HeroTabs"

const CImg = chakra(GatsbyImage)

// TODO: Unused props: country, noHero
interface Props {
  copy?: string
  country?: string
  noHero?: boolean
  image?: ImageDataLike
  hideHeroTabs?: boolean
}

export const Hero = ({ copy, image, hideHeroTabs }: Props) => {
  const height = image ? { height: ["197px", "560px", "700px"] } : { height: "20rem", backgroundColor: "primary" }

  return (
    <>
      {image && (
        <CImg
          alt=""
          image={getImage(image)}
          sx={{
            position: "absolute !important",
            width: "100%",
            ...height,
          }}
        />
      )}
      <Section
        sx={{
          backgroundColor: "transparent",
          position: "relative",
          ...height,
        }}
      >
        <Heading
          mt={[16, 0]}
          fontSize={["1.875rem", "4.5rem"]}
          as="h1"
          fontWeight="normal"
          color="white"
          textAlign="center"
        >
          {copy}
        </Heading>
        <chakra.a
          href="https://uk.trustpilot.com/review/highsails.com"
          target="_blank"
          rel="noreferrer"
          sx={{
            position: "absolute",
            bottom: "-50px",
            right: [0, "50px"],
            transform: ["scale(0.47)", "scale(1)"],
            "&:hover": {
              filter: "brightness(110%)",
              transition: "filter .1s ease-out",
            },
          }}
        >
          <StaticImage
            src="../images/trustpilot.png"
            alt="TrustPilot Logo"
            placeholder="blurred"
            layout="fixed"
            width={100}
            height={100}
          />
        </chakra.a>
      </Section>
      <Center
        sx={{
          width: "100%",
          position: [null, "absolute"],
          top: [null, "455px", "500px"],
        }}
      ></Center>
    </>
  )
}

export default Hero
