import React from "react"
import { Box, Center, Heading, forwardRef } from "@chakra-ui/react"

const Section = forwardRef(
  ({ as, divider, headline, highlighted, children, ...rest }, ref) => {
    const dividerCSS = divider
      ? {
          "::after": {
            display: ["none", "block"],
            content: '""',
            borderTop: (theme) => `solid 1px ${theme.colors.secondary}`,
            width: "100%",
            height: "1px",
            position: "absolute",
            top: "50%",
            zIndex: 1,
          },

          "& > span": {
            background: "white",
            padding: ["0", "0 100px"],
            position: "relative",
            zIndex: 5,
          },
        }
      : {}

    return (
      <Center
        as={as || "section"}
        ref={ref}
        bg={highlighted ? "secondaryBg" : "white"}
        {...rest}
      >
        <Box w="90%" maxW="1280px">
          {headline && (
            <Heading
              size="2xl"
              textAlign="center"
              position="relative"
              as="h2"
              color="primary"
              fontWeight="normal"
              sx={{ ...dividerCSS }}
            >
              <span>{headline}</span>
            </Heading>
          )}
          {children}
        </Box>
      </Center>
    )
  }
)

export default Section
