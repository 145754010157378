import React from "react"
import { FaFacebookF, FaInstagram } from "react-icons/fa"
import {
  Box,
  Heading,
  Icon,
  Text,
  Flex,
  VStack,
  chakra,
} from "@chakra-ui/react"

import Link from "./Link"
import { LogoWhiteX } from "./Logos"
import Section from "./Section"

const Footer = () => (
  <Section
    mt={16}
    as="footer"
    sx={{
      color: "white",
      bg: "primary",

      h4: {
        color: "white",
      },

      a: {
        color: "white",
      },
    }}
    py={16}
  >
    <Box
      sx={{
        display: "grid",
        gridGap: 4,
        gridTemplateColumns: ["1fr", "repeat(3, 1fr)"],
      }}
    >
      <VStack my={4}>
        <Heading as="h4" variant="h4" size="md" my={4}>
          ABOUT
        </Heading>
        <Text as={Link} to="/privacy-policy">
          Privacy Policy
        </Text>
        <Text as={Link} to="/terms-conditions">
          Terms and Conditions
        </Text>
      </VStack>
      <VStack my={4}>
        <Heading as="h4" variant="h4" size="md" my={4}>
          HIGHSAILS
        </Heading>
        <span>Mike Tripala 4, 21 000 Split, Croatia</span>
        <span>info@highsails.com</span>
      </VStack>
      <VStack my={4}>
        <Heading as="h4" variant="h4" size="md" my={4}>
          FOLLOW US
        </Heading>

        <Flex>
          <a href="https://www.facebook.com/thehighsails">
            <Icon mx={8} as={FaFacebookF} fill="white" />
          </a>
          <a href="https://www.instagram.com/highsails_/">
            <Icon mx={8} as={FaInstagram} fill="white" />
          </a>
        </Flex>
        <Flex>
          <chakra.a
            href="https://uk.trustpilot.com/review/highsails.com"
            target="_blank"
            rel="noreferrer"
            sx={{
              color: "white",
              gridColumn: ["1 / 3", "1 / 5"],
              gridRow: 2,
              textAlign: "left",
              width: "max-content",
            }}
          >
            Rated Excellent at
            <chakra.span sx={{ color: "#00B67A" }}> ✭</chakra.span>
            Trustpilot
          </chakra.a>
        </Flex>
      </VStack>
      <Box
        sx={{
          my: 12,
          display: "flex",
          flexDirection: ["column", "row"],
          alignItems: "center",
          gridColumn: "1 / -1",
        }}
      >
        <chakra.a lineHeight={0} to="/" mb={[4, 0]}>
          <LogoWhiteX h="32px" w="78px" />
        </chakra.a>
        <chakra.span c="#d8d8d8" ml={10}>
          {`© HighSails ${new Date().getFullYear()} - All rights reserved`}
        </chakra.span>
      </Box>
    </Box>
  </Section>
)

export default Footer
