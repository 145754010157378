module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "HighSails", // Navigation and Site Title
  titleAlt: "HighSails", // Title for JSONLD
  description: "Experience the perfect sailing trip",
  headline: "Experience the perfect sailing trip", // Headline for schema.org JSONLD
  url: "https://www.highsails.com", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  logo: "/logo.jpg", // Used for SEO,
  banner:
    "https://images.prismic.io/highsails/b790d38e-24be-4406-af49-88b6aab38c21_white-catamaran-yacht-at-anchor-in-calm-clear-azur-EABBQ6P.jpg?auto=format%2Ccompress&rect=0%2C0%2C4140%2C2174&w=1200&h=630&fit=max&q=50",
  ogLanguage: "en_US", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/images/favicon.png", // Used for manifest favicon generation
  shortName: "HighSails", // shortname for manifest. MUST be shorter than 12 characters
  author: "HighSails", // Author for schemaORGJSONLD
  themeColor: "#0C4577",
  backgroundColor: "#0C4577",

  twitter: "@highsails", // Twitter Username
  facebook: "HighSails", // Facebook Site Name
  googleAnalyticsID: "UA-164579194-1",
}
