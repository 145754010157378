import { humanize } from "../helpers/text"
import { Boat } from "../types/boat"

export const BOAT_TYPES = [
  { label: "All Boat Types", value: "all" },
  { label: "Sailboat", value: "sailboat" },
  { label: "Catamaran", value: "catamaran" },
  { label: "Motor Boat", value: "motorboat" },
  { label: "Gulet", value: "gulet" },
]

export const boatAboutText = (boat: Boat) => {
  switch (humanize(boat.kind)) {
    case "Sailboat":
      return `Here you have the lovely ${boat.name}, a fine example of a/an ${boat.modelName} made by ${
        boat.brand
      }. This ${humanize(
        boat.kind
      )} is perfect for any thrill seekers looking to open the sails and  speed away into the sunset. With this ${
        boat.year
      } edition you can sleep up to ${boat.berthsTotal} of your nearest and dearest spread across ${
        boat.cabins
      } cabins. Note that with a sailboat you are likely to have a bit less space than you would find on a similar length catamaran, but what a ${humanize(
        boat.kind
      )} lacks in space it makes up for in adventure. You won’t be disappointed if you choose this ${humanize(
        boat.kind
      )}.`
    case "Catamaran":
      return `Look no further than ${boat.name}, a beautiful ${boat.modelName} made by ${boat.brand}. With this ${
        boat.year
      } edition you can sleep up to ${boat.berthsTotal} of your nearest and dearest spread across ${
        boat.cabins
      } cabins. This ${humanize(
        boat.kind
      )} is perfect for anybody wanting to kick back, relax and make the most of the extra space compared to a sailboat of a similar length. Catamarans are notoriously comfy as they take advantage of the extra space made by the flybridge, and ${
        boat.name
      } is no exception. If you want to travel in style, this is the way to go.`
    case "Motorboat":
      return `Not to be missed, ${boat.name} is a fine example of a/an ${boat.modelName} made by ${boat.brand}. With this ${boat.year} edition you can sleep up to ${boat.berthsTotal} of your closest friends and family spread across ${boat.cabins} cabins.This motorboat is a great choice for those looking to power ahead full throttle and not worry about having to man any sails. If you want to see as many different places as possible, then a motorboat is the best option as you’ll get from A to B much faster then on a catamaran or even sailboat.`
    default:
      return `Here you have the lovely ${boat.name}, a fine example of a/an ${boat.modelName} made by ${
        boat.brand
      }. This ${humanize(
        boat.kind
      )} is perfect for any thrill seekers looking to open the sails and  speed away into the sunset. With this ${
        boat.year
      } edition you can sleep up to ${boat.berthsTotal} of your nearest and dearest spread across ${
        boat.cabins
      } cabins. Note that with a sailboat you are likely to have a bit less space than you would find on a similar length catamaran, but what a ${humanize(
        boat.kind
      )} lacks in space it makes up for in adventure. You won’t be disappointed if you choose this ${humanize(
        boat.kind
      )}.`
  }
}

export const boatLocationText = (boat: Boat) => {
  return `You’ll find ${boat.name} docked in ${boat.base.name}, ${boat.base.country}. In and around the marina you can find most of the basic things you might need for your trip.`
}
