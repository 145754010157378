const resolveDestinationLink = (doc) => {
  let dest

  if (doc.data.country) dest = doc.data.country.toLowerCase()
  else
    throw new Error(
      `Country value in destination guides ${doc.data.uid} cannot be empty`
    )

  if (doc.data.articletype === "Destination Hub") {
    return `/${dest}/`
  }
  return `/${dest}/${doc.uid}/`
}

const resolveSailingTipsLink = (doc) => {
  if (doc.data.articletype === "Sailing Tips Guide") {
    return `/sailing-tips/${doc.uid}/`
  }
  return `/sailing-tips/`
}

const resolveInspirationLink = (doc) => {
  if (doc.data.articletype === "Inspiration Hub") {
    return `/get-inspired/`
  }
  return `/get-inspired/${doc.uid}/`
}

const resolveArticleLink = (doc) => {
  const destinationArticles = [
    "Destination Hub",
    "Destination Guide",
    "Destination Sailing Guide",
    "Destination Top Guide",
  ]
  const sailingTipsArticles = ["Sailing Tips Hub", "Sailing Tips Guide"]
  const inspirationArticles = [
    "Inspiration Hub",
    "Inspiration Sailing Guide",
    "Inspiration Top Guide",
  ]
  if (doc.data) {
    if (destinationArticles.includes(doc.data.articletype)) {
      return resolveDestinationLink(doc)
    }
    if (sailingTipsArticles.includes(doc.data.articletype)) {
      return resolveSailingTipsLink(doc)
    }
    if (inspirationArticles.includes(doc.data.articletype)) {
      return resolveInspirationLink(doc)
    }
    return `/${doc.uid}/`
  }
  return `/${doc.uid}/`
}

const resolveAuthorLink = (doc) => {
  return `/authors/${doc.uid}/`
}

const resolveBoatLink = (doc) => {
  return `/boats/${doc.uid}/`
}

exports.linkResolver = function linkResolver(doc) {
  switch (doc.type) {
    case "hyperlink":
      switch (doc.data.link_type) {
        case "Web":
          return doc.data.url
        case "Document":
          return resolveArticleLink(doc.data)
        default:
          return "/"
      }
    case "article":
      return resolveArticleLink(doc)
    case "boat":
      return resolveBoatLink(doc)
    case "author":
      return resolveAuthorLink(doc)
    default:
      return "/"
  }
}
