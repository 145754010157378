import React from "react"
import { Icon } from "@chakra-ui/react"
import PropTypes from "prop-types"

export const LogoWhiteX = (props) => (
  <Icon viewBox="0 0 117 48" fill="none" {...props}>
    <path
      d="M81.2316 38.5054C80.526 38.5054 79.8653 38.4477 79.2495 38.3324C78.6337 38.2042 78.0628 38.012 77.5368 37.7557C77.0108 37.4866 76.5362 37.1598 76.1128 36.7753L76.7093 35.5643C77.4021 36.141 78.0949 36.551 78.7877 36.7945C79.4933 37.038 80.3143 37.1598 81.2508 37.1598C82.3413 37.1598 83.188 36.9483 83.791 36.5254C84.394 36.0897 84.6955 35.481 84.6955 34.6992C84.6955 34.2251 84.5415 33.847 84.2336 33.5651C83.9385 33.2703 83.528 33.0396 83.002 32.873C82.4889 32.6936 81.9051 32.527 81.2508 32.3732C80.5966 32.2323 79.9743 32.0721 79.3842 31.8927C78.7941 31.7004 78.2681 31.4633 77.8062 31.1814C77.3572 30.8995 77.0044 30.5406 76.7478 30.1049C76.4913 29.6692 76.363 29.1245 76.363 28.471C76.363 27.6892 76.5682 27.0036 76.9788 26.4141C77.3893 25.8118 77.9666 25.344 78.7107 25.0108C79.4548 24.6776 80.3336 24.511 81.3471 24.511C81.9757 24.511 82.5787 24.5815 83.156 24.7225C83.7333 24.8506 84.2657 25.0428 84.7532 25.2991C85.2535 25.5555 85.6961 25.8694 86.081 26.2411L85.4652 27.4521C84.8366 26.9011 84.1887 26.4974 83.5216 26.2411C82.8545 25.9848 82.1296 25.8566 81.3471 25.8566C80.2822 25.8566 79.4484 26.0809 78.8454 26.5294C78.2424 26.978 77.9409 27.6059 77.9409 28.4133C77.9409 28.9131 78.0756 29.3168 78.3451 29.6243C78.6145 29.9319 78.9929 30.1818 79.4804 30.374C79.9679 30.5535 80.526 30.7201 81.1546 30.8738C81.8217 31.0276 82.4632 31.1942 83.079 31.3736C83.6948 31.5531 84.24 31.7773 84.7147 32.0464C85.2022 32.3028 85.5871 32.6424 85.8693 33.0653C86.1516 33.4754 86.2927 34.0008 86.2927 34.6416C86.2927 35.4233 86.0874 36.1025 85.6769 36.6792C85.2663 37.2559 84.6826 37.7044 83.9257 38.0248C83.1688 38.3452 82.2708 38.5054 81.2316 38.5054Z"
      fill="white"
    />
    <path
      d="M92.3938 38.4862C91.5343 38.4862 90.7773 38.2875 90.1231 37.8903C89.4816 37.4802 88.9813 36.9035 88.6221 36.1602C88.2757 35.4041 88.1025 34.5198 88.1025 33.5074C88.1025 32.495 88.2821 31.6107 88.6413 30.8546C89.0005 30.0985 89.5009 29.509 90.1423 29.0861C90.7966 28.6632 91.5471 28.4517 92.3938 28.4517C93.279 28.4517 94.0295 28.6696 94.6453 29.1053C95.2611 29.541 95.6781 30.1626 95.8962 30.97L95.6652 31.1237V28.6824H97.2047V38.3516H95.6652V35.8526L95.8962 35.9679C95.6781 36.7753 95.2611 37.3969 94.6453 37.8326C94.0295 38.2683 93.279 38.4862 92.3938 38.4862ZM92.7017 37.1982C93.6511 37.1982 94.3823 36.8778 94.8955 36.2371C95.4086 35.5835 95.6652 34.6608 95.6652 33.4689C95.6652 32.2771 95.4022 31.3608 94.8762 30.7201C94.3631 30.0793 93.6382 29.7589 92.7017 29.7589C91.7524 29.7589 91.0083 30.0921 90.4694 30.7585C89.9435 31.4121 89.6805 32.3284 89.6805 33.5074C89.6805 34.6864 89.9435 35.5963 90.4694 36.2371C91.0083 36.8778 91.7524 37.1982 92.7017 37.1982Z"
      fill="white"
    />
    <path
      d="M100.218 38.3516V28.6824H101.777V38.3516H100.218ZM100.065 24.5879H101.97V26.3564H100.065V24.5879Z"
      fill="white"
    />
    <path d="M104.804 38.3516V24.2034H106.363V38.3516H104.804Z" fill="white" />
    <path
      d="M112.661 38.4862C111.031 38.4862 109.742 38.0697 108.793 37.2367L109.312 36.0833C109.825 36.4806 110.351 36.7753 110.89 36.9675C111.442 37.147 112.051 37.2367 112.718 37.2367C113.45 37.2367 114.001 37.1149 114.373 36.8714C114.758 36.6151 114.951 36.2499 114.951 35.7757C114.951 35.3913 114.822 35.0901 114.566 34.8722C114.309 34.6416 113.886 34.4557 113.296 34.3148L111.66 33.9303C110.852 33.7509 110.223 33.4305 109.774 32.9692C109.338 32.5078 109.12 31.9567 109.12 31.316C109.12 30.7521 109.274 30.2587 109.582 29.8358C109.89 29.4001 110.319 29.0605 110.871 28.817C111.423 28.5735 112.077 28.4517 112.834 28.4517C113.514 28.4517 114.155 28.5607 114.758 28.7785C115.361 28.9964 115.868 29.3104 116.278 29.7205L115.759 30.8546C115.297 30.4702 114.822 30.1882 114.335 30.0088C113.86 29.8166 113.36 29.7205 112.834 29.7205C112.141 29.7205 111.602 29.855 111.217 30.1241C110.845 30.3933 110.659 30.7585 110.659 31.2199C110.659 31.6171 110.775 31.9311 111.006 32.1618C111.249 32.3925 111.634 32.5719 112.16 32.7L113.796 33.1037C114.707 33.2959 115.374 33.6163 115.797 34.0649C116.234 34.5006 116.452 35.0581 116.452 35.7373C116.452 36.5703 116.112 37.2367 115.432 37.7365C114.752 38.2363 113.828 38.4862 112.661 38.4862Z"
      fill="white"
    />
    <path
      d="M67.9657 14.3536V0.468053H69.5512V14.3536H67.9657ZM58.1436 14.3536V0.468053H59.7097V14.3536H58.1436ZM58.8783 7.97638V6.63073H68.8551V7.97638H58.8783Z"
      fill="white"
    />
    <path
      d="M72.7535 14.3536V4.544H74.3196V14.3536H72.7535ZM72.5988 0.390044H74.513V2.18424H72.5988V0.390044Z"
      fill="white"
    />
    <path
      d="M81.6337 18.7026C80.1642 18.7026 78.8881 18.3645 77.8054 17.6885L78.0761 16.4013C78.6819 16.7524 79.2555 17.0059 79.7969 17.1619C80.3382 17.3179 80.9505 17.3959 81.6337 17.3959C82.5618 17.3959 83.2643 17.1424 83.7412 16.6353C84.231 16.1413 84.4759 15.4002 84.4759 14.4121V11.7598L84.6886 11.5063C84.5339 12.0263 84.289 12.4814 83.9539 12.8714C83.6316 13.2485 83.232 13.541 82.7551 13.749C82.2782 13.957 81.7368 14.0611 81.131 14.0611C80.2673 14.0611 79.5068 13.8595 78.8494 13.4565C78.205 13.0404 77.6958 12.4684 77.322 11.7403C76.9611 11.0122 76.7806 10.1606 76.7806 9.18552C76.7806 8.21041 76.9611 7.35881 77.322 6.63073C77.6958 5.90265 78.205 5.33709 78.8494 4.93405C79.5068 4.518 80.2673 4.30998 81.131 4.30998C82.0333 4.30998 82.7938 4.5375 83.4125 4.99255C84.0441 5.4346 84.4695 6.05867 84.6886 6.86476L84.4566 6.65024V4.544H86.0227V14.2756C86.0227 15.7447 85.6489 16.8499 84.9013 17.5909C84.1666 18.332 83.0774 18.7026 81.6337 18.7026ZM81.4403 12.7349C82.3813 12.7349 83.116 12.4229 83.6445 11.7988C84.1859 11.1617 84.4566 10.2906 84.4566 9.18552C84.4566 8.08039 84.1859 7.2158 83.6445 6.59173C83.116 5.95466 82.3813 5.63612 81.4403 5.63612C80.4865 5.63612 79.7324 5.95466 79.1781 6.59173C78.6368 7.2158 78.3661 8.08039 78.3661 9.18552C78.3661 10.3036 78.6368 11.1747 79.1781 11.7988C79.7324 12.4229 80.4865 12.7349 81.4403 12.7349Z"
      fill="white"
    />
    <path
      d="M89.0296 14.3536V0H90.5957V6.53322L90.3444 6.72824C90.6151 5.93516 91.0662 5.33709 91.6978 4.93405C92.3294 4.518 93.0577 4.30998 93.8827 4.30998C96.2157 4.30998 97.3823 5.60362 97.3823 8.19091V14.3536H95.8161V8.26892C95.8161 7.35881 95.6357 6.69574 95.2748 6.27969C94.9138 5.85065 94.3467 5.63612 93.5733 5.63612C92.671 5.63612 91.9492 5.91565 91.4078 6.47472C90.8664 7.03378 90.5957 7.78786 90.5957 8.73697V14.3536H89.0296Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 47.9997H70.4647V46.0744H0V47.9997Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.1416 38.5053L27.3374 0L27.3374 38.5053H58.1416Z"
      fill="white"
    />
  </Icon>
)

const whiteYCss = `
  .st0{fill:#FFFFFF;}
  `
export const LogoWhiteY = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 342 339.29999"
    y="0px"
    x="0px"
    id="Layer_1"
    version="1.1"
  >
    <style id="style10" type="text/css">
      {whiteYCss}
    </style>
    <g transform="translate(-250,-127)" id="g34">
      <path id="path12" d="m 250,466.3 h 342 v -6.7 H 250 Z" className="st0" />
      <path
        id="path14"
        d="m 415.6,427.6 c -2.6,0 -5,-0.2 -7.2,-0.6 -2.2,-0.5 -4.3,-1.2 -6.2,-2.1 -1.9,-1 -3.6,-2.2 -5.2,-3.6 l 2.2,-4.4 c 2.5,2.1 5,3.6 7.6,4.5 2.6,0.9 5.6,1.3 9,1.3 4,0 7.1,-0.8 9.3,-2.3 2.2,-1.6 3.3,-3.8 3.3,-6.7 0,-1.7 -0.6,-3.1 -1.7,-4.1 -1.1,-1.1 -2.6,-1.9 -4.5,-2.5 -1.9,-0.7 -4,-1.3 -6.4,-1.8 -2.4,-0.5 -4.6,-1.1 -6.8,-1.8 -2.1,-0.7 -4.1,-1.6 -5.7,-2.6 -1.6,-1 -2.9,-2.3 -3.9,-3.9 -0.9,-1.6 -1.4,-3.6 -1.4,-6 0,-2.8 0.7,-5.3 2.2,-7.5 1.5,-2.2 3.6,-3.9 6.3,-5.1 2.7,-1.2 5.9,-1.8 9.6,-1.8 2.3,0 4.5,0.3 6.6,0.8 2.1,0.5 4,1.2 5.8,2.1 1.8,0.9 3.4,2.1 4.8,3.4 l -2.2,4.4 c -2.3,-2 -4.7,-3.5 -7.1,-4.4 -2.4,-0.9 -5.1,-1.4 -7.9,-1.4 -3.9,0 -6.9,0.8 -9.1,2.5 -2.2,1.6 -3.3,3.9 -3.3,6.9 0,1.8 0.5,3.3 1.5,4.4 1,1.1 2.4,2 4.1,2.7 1.8,0.7 3.8,1.3 6.1,1.8 2.4,0.6 4.8,1.2 7,1.8 2.2,0.7 4.2,1.5 6,2.5 1.8,0.9 3.2,2.2 4.2,3.7 1,1.5 1.5,3.4 1.5,5.7 0,2.8 -0.7,5.3 -2.2,7.4 -1.5,2.1 -3.6,3.7 -6.4,4.9 -2.8,1.2 -6.1,1.8 -9.9,1.8 z"
        className="st0"
      />
      <path
        id="path16"
        d="m 456.3,427.5 c -3.1,0 -5.9,-0.7 -8.3,-2.2 -2.3,-1.5 -4.2,-3.6 -5.5,-6.3 -1.3,-2.8 -1.9,-6 -1.9,-9.7 0,-3.7 0.7,-6.9 2,-9.7 1.3,-2.8 3.1,-4.9 5.5,-6.4 2.4,-1.5 5.1,-2.3 8.2,-2.3 3.2,0 6,0.8 8.2,2.4 2.2,1.6 3.8,3.9 4.6,6.8 l -0.8,0.6 v -8.9 h 5.6 V 427 h -5.6 v -9.1 l 0.8,0.4 c -0.8,2.9 -2.3,5.2 -4.6,6.8 -2.3,1.6 -5,2.4 -8.2,2.4 z m 1.1,-4.7 c 3.5,0 6.1,-1.2 8,-3.5 1.9,-2.4 2.8,-5.7 2.8,-10.1 0,-4.3 -1,-7.7 -2.9,-10 -1.9,-2.3 -4.5,-3.5 -7.9,-3.5 -3.5,0 -6.2,1.2 -8.1,3.6 -1.9,2.4 -2.9,5.7 -2.9,10 0,4.3 1,7.6 2.9,9.9 1.9,2.5 4.6,3.6 8.1,3.6 z"
        className="st0"
      />
      <path
        id="path18"
        d="m 484.8,427 v -35.2 h 5.7 V 427 Z m -0.6,-50.1 h 6.9 v 6.4 h -6.9 z"
        className="st0"
      />
      <path id="path20" d="m 501.5,427 v -51.5 h 5.7 V 427 Z" className="st0" />
      <path
        id="path22"
        d="m 530.1,427.5 c -5.9,0 -10.6,-1.5 -14.1,-4.6 l 1.9,-4.2 c 1.9,1.4 3.8,2.5 5.7,3.2 2,0.7 4.2,1 6.7,1 2.7,0 4.7,-0.4 6,-1.3 1.4,-0.9 2.1,-2.3 2.1,-4 0,-1.4 -0.5,-2.5 -1.4,-3.3 -0.9,-0.8 -2.5,-1.5 -4.6,-2 l -6,-1.4 c -2.9,-0.7 -5.2,-1.8 -6.9,-3.5 -1.6,-1.7 -2.4,-3.7 -2.4,-6 0,-2.1 0.6,-3.9 1.7,-5.4 1.1,-1.6 2.7,-2.8 4.7,-3.7 2,-0.9 4.4,-1.3 7.2,-1.3 2.5,0 4.8,0.4 7,1.2 2.2,0.8 4,1.9 5.5,3.4 l -1.9,4.1 c -1.7,-1.4 -3.4,-2.4 -5.2,-3.1 -1.7,-0.7 -3.6,-1 -5.5,-1 -2.5,0 -4.5,0.5 -5.9,1.5 -1.4,1 -2,2.3 -2,4 0,1.4 0.4,2.6 1.3,3.4 0.9,0.8 2.3,1.5 4.2,2 l 6,1.5 c 3.3,0.7 5.7,1.9 7.3,3.5 1.6,1.6 2.4,3.6 2.4,6.1 0,3 -1.2,5.5 -3.7,7.3 -2.5,1.7 -5.9,2.6 -10.1,2.6 z"
        className="st0"
      />
      <path id="path24" d="M 445,260.6 338.2,127 v 133.6 z" className="st0" />
      <path
        id="path26"
        d="M 380.6,339.6 V 289 h 5.8 v 50.6 z m -35.7,0 V 289 h 5.7 v 50.6 z m 2.6,-23.2 v -4.9 h 36.3 v 4.9 z"
        className="st0"
      />
      <path
        id="path28"
        d="m 398.1,339.6 v -35.7 h 5.7 v 35.7 z m -0.6,-50.9 h 7 v 6.5 h -7 z"
        className="st0"
      />
      <path
        id="path30"
        d="m 430.4,355.4 c -5.4,0 -10,-1.2 -13.9,-3.7 l 1,-4.7 c 2.2,1.3 4.3,2.2 6.3,2.8 2,0.6 4.2,0.9 6.7,0.9 3.4,0 5.9,-0.9 7.7,-2.8 1.8,-1.8 2.7,-4.5 2.7,-8.1 v -9.7 l 0.8,-0.9 c -0.6,1.9 -1.5,3.6 -2.7,5 -1.2,1.4 -2.6,2.4 -4.4,3.2 -1.7,0.8 -3.7,1.1 -5.9,1.1 -3.1,0 -5.9,-0.7 -8.3,-2.2 -2.3,-1.5 -4.2,-3.6 -5.6,-6.3 -1.3,-2.7 -2,-5.8 -2,-9.3 0,-3.5 0.7,-6.7 2,-9.3 1.4,-2.7 3.2,-4.7 5.6,-6.2 2.4,-1.5 5.2,-2.3 8.3,-2.3 3.3,0 6.1,0.8 8.3,2.5 2.3,1.6 3.9,3.9 4.6,6.8 l -0.8,-0.8 v -7.7 h 5.7 v 35.5 c 0,5.4 -1.4,9.4 -4.1,12.1 -2.7,2.8 -6.7,4.1 -12,4.1 z m -0.7,-21.7 c 3.4,0 6.1,-1.1 8,-3.4 2,-2.3 3,-5.5 3,-9.5 0,-4 -1,-7.2 -3,-9.4 -1.9,-2.3 -4.6,-3.5 -8,-3.5 -3.5,0 -6.2,1.2 -8.2,3.5 -2,2.3 -3,5.4 -3,9.4 0,4.1 1,7.2 3,9.5 2,2.3 4.7,3.4 8.2,3.4 z"
        className="st0"
      />
      <path
        id="path32"
        d="m 457.4,339.6 v -52.3 h 5.7 v 23.8 l -0.9,0.7 c 1,-2.9 2.6,-5.1 4.9,-6.5 2.3,-1.5 5,-2.3 8,-2.3 8.5,0 12.7,4.7 12.7,14.1 v 22.4 h -5.7 v -22.2 c 0,-3.3 -0.7,-5.7 -2,-7.2 -1.3,-1.6 -3.4,-2.3 -6.2,-2.3 -3.3,0 -5.9,1 -7.9,3.1 -2,2 -3,4.8 -3,8.2 v 20.5 z"
        className="st0"
      />
    </g>
  </svg>
)

const colourXCss = `
	.st0{fill:#0C4577;}
	.st1{fill:#B8AC9C;}`

export const LogoColourX = (props) => (
  <Icon viewBox="0 0 116.5 48" {...props}>
    <style id="style110" type="text/css">
      {colourXCss}
    </style>
    <path
      id="path112"
      d="m 81.2,38.5 c -0.7,0 -1.4,-0.1 -2,-0.2 -0.6,-0.1 -1.2,-0.3 -1.7,-0.6 -0.5,-0.3 -1,-0.6 -1.4,-1 l 0.6,-1.2 c 0.7,0.6 1.4,1 2.1,1.2 0.7,0.2 1.5,0.4 2.5,0.4 1.1,0 1.9,-0.2 2.5,-0.6 0.6,-0.4 0.9,-1 0.9,-1.8 0,-0.5 -0.2,-0.9 -0.5,-1.1 -0.3,-0.3 -0.7,-0.5 -1.2,-0.7 -0.5,-0.2 -1.1,-0.3 -1.8,-0.5 -0.7,-0.1 -1.3,-0.3 -1.9,-0.5 -0.6,-0.2 -1.1,-0.4 -1.6,-0.7 -0.4,-0.3 -0.8,-0.6 -1.1,-1.1 -0.3,-0.4 -0.4,-1 -0.4,-1.6 0,-0.8 0.2,-1.5 0.6,-2.1 0.4,-0.6 1,-1.1 1.7,-1.4 0.7,-0.3 1.6,-0.5 2.6,-0.5 0.6,0 1.2,0.1 1.8,0.2 0.6,0.1 1.1,0.3 1.6,0.6 0.5,0.3 0.9,0.6 1.3,0.9 l -0.6,1.2 c -0.6,-0.6 -1.3,-1 -1.9,-1.2 -0.6,-0.2 -1.4,-0.4 -2.2,-0.4 -1.1,0 -1.9,0.2 -2.5,0.7 -0.6,0.4 -0.9,1.1 -0.9,1.9 0,0.5 0.1,0.9 0.4,1.2 0.3,0.3 0.6,0.6 1.1,0.7 0.5,0.2 1,0.3 1.7,0.5 0.7,0.2 1.3,0.3 1.9,0.5 0.6,0.2 1.2,0.4 1.6,0.7 0.5,0.3 0.9,0.6 1.2,1 0.3,0.4 0.4,0.9 0.4,1.6 0,0.8 -0.2,1.5 -0.6,2 -0.4,0.6 -1,1 -1.8,1.3 -0.4,0.4 -1.3,0.6 -2.4,0.6 z"
      className="st0"
    />
    <path
      id="path114"
      d="m 92.4,38.5 c -0.9,0 -1.6,-0.2 -2.3,-0.6 -0.6,-0.4 -1.1,-1 -1.5,-1.7 -0.3,-0.8 -0.5,-1.6 -0.5,-2.7 0,-1 0.2,-1.9 0.5,-2.7 0.4,-0.8 0.9,-1.3 1.5,-1.8 0.7,-0.4 1.4,-0.6 2.3,-0.6 0.9,0 1.6,0.2 2.3,0.7 0.6,0.4 1,1.1 1.3,1.9 l -0.2,0.2 v -2.4 h 1.5 v 9.7 H 95.8 V 36 l 0.2,0.1 c -0.2,0.8 -0.6,1.4 -1.3,1.9 -0.7,0.5 -1.4,0.5 -2.3,0.5 z m 0.3,-1.3 c 0.9,0 1.7,-0.3 2.2,-1 0.5,-0.7 0.8,-1.6 0.8,-2.8 0,-1.2 -0.3,-2.1 -0.8,-2.7 -0.5,-0.6 -1.2,-1 -2.2,-1 -0.9,0 -1.7,0.3 -2.2,1 -0.5,0.7 -0.8,1.6 -0.8,2.7 0,1.1 0.3,2.1 0.8,2.7 0.5,0.8 1.3,1.1 2.2,1.1 z"
      className="st0"
    />
    <path
      id="path116"
      d="m 100.2,38.4 v -9.7 h 1.6 v 9.7 z m -0.1,-13.8 h 1.9 v 1.8 h -1.9 z"
      className="st0"
    />
    <path
      id="path118"
      d="M 104.8,38.4 V 24.2 h 1.6 v 14.1 h -1.6 z"
      className="st0"
    />
    <path
      id="path120"
      d="m 112.7,38.5 c -1.6,0 -2.9,-0.4 -3.9,-1.2 l 0.5,-1.2 c 0.5,0.4 1,0.7 1.6,0.9 0.6,0.2 1.2,0.3 1.8,0.3 0.7,0 1.3,-0.1 1.7,-0.4 0.4,-0.3 0.6,-0.6 0.6,-1.1 0,-0.4 -0.1,-0.7 -0.4,-0.9 -0.3,-0.2 -0.7,-0.4 -1.3,-0.6 l -1.6,-0.4 c -0.8,-0.2 -1.4,-0.5 -1.9,-1 -0.4,-0.5 -0.7,-1 -0.7,-1.7 0,-0.6 0.2,-1.1 0.5,-1.5 0.3,-0.4 0.7,-0.8 1.3,-1 0.6,-0.2 1.2,-0.4 2,-0.4 0.7,0 1.3,0.1 1.9,0.3 0.6,0.2 1.1,0.5 1.5,0.9 l -0.5,1.1 c -0.5,-0.4 -0.9,-0.7 -1.4,-0.8 -0.5,-0.2 -1,-0.3 -1.5,-0.3 -0.7,0 -1.2,0.1 -1.6,0.4 -0.4,0.3 -0.6,0.6 -0.6,1.1 0,0.4 0.1,0.7 0.3,0.9 0.2,0.2 0.6,0.4 1.2,0.5 l 1.6,0.4 c 0.9,0.2 1.6,0.5 2,1 0.4,0.4 0.7,1 0.7,1.7 0,0.8 -0.3,1.5 -1,2 -0.7,0.5 -1.7,1 -2.8,1 z"
      className="st0"
    />
    <path
      id="path122"
      d="M 68,14.4 V 0.5 h 1.6 v 13.9 z m -9.9,0 V 0.5 h 1.6 V 14.4 Z M 58.9,8 V 6.6 h 10 V 8 Z"
      className="st0"
    />
    <path
      id="path124"
      d="M 72.8,14.4 V 4.5 h 1.6 v 9.8 h -1.6 z m -0.2,-14 h 1.9 v 1.8 h -1.9 z"
      className="st0"
    />
    <path
      id="path126"
      d="m 81.6,18.7 c -1.5,0 -2.7,-0.3 -3.8,-1 l 0.3,-1.3 c 0.6,0.4 1.2,0.6 1.7,0.8 0.5,0.2 1.2,0.2 1.8,0.2 0.9,0 1.6,-0.3 2.1,-0.8 0.5,-0.5 0.7,-1.2 0.7,-2.2 v -2.7 l 0.2,-0.3 c -0.2,0.5 -0.4,1 -0.7,1.4 -0.3,0.4 -0.7,0.7 -1.2,0.9 -0.5,0.2 -1,0.3 -1.6,0.3 -0.9,0 -1.6,-0.2 -2.3,-0.6 -0.6,-0.4 -1.2,-1 -1.5,-1.7 -0.4,-0.7 -0.5,-1.6 -0.5,-2.6 0,-1 0.2,-1.8 0.5,-2.6 0.4,-0.7 0.9,-1.3 1.5,-1.7 0.7,-0.4 1.4,-0.6 2.3,-0.6 0.9,0 1.7,0.2 2.3,0.7 0.6,0.4 1.1,1.1 1.3,1.9 L 84.5,6.6 V 4.5 H 86 v 9.7 c 0,1.5 -0.4,2.6 -1.1,3.3 -0.7,0.8 -1.8,1.2 -3.3,1.2 z m -0.2,-6 c 0.9,0 1.7,-0.3 2.2,-0.9 0.5,-0.6 0.8,-1.5 0.8,-2.6 0,-1.1 -0.3,-2 -0.8,-2.6 -0.5,-0.6 -1.3,-1 -2.2,-1 -1,0 -1.7,0.3 -2.3,1 -0.5,0.6 -0.8,1.5 -0.8,2.6 0,1.1 0.3,2 0.8,2.6 0.6,0.6 1.4,0.9 2.3,0.9 z"
      className="st0"
    />
    <path
      id="path128"
      d="M 89,14.4 V 0 h 1.6 v 6.5 l -0.3,0.2 c 0.3,-0.8 0.7,-1.4 1.4,-1.8 0.6,-0.4 1.4,-0.6 2.2,-0.6 2.3,0 3.5,1.3 3.5,3.9 v 6.2 H 95.8 V 8.3 C 95.8,7.4 95.6,6.7 95.3,6.3 94.9,5.9 94.4,5.7 93.6,5.7 92.7,5.7 92,6 91.4,6.5 90.9,7.1 90.6,7.8 90.6,8.8 v 5.6 z"
      className="st0"
    />
    <path id="path130" d="M 0,48 H 70.5 V 46.1 H 0 Z" className="st1" />
    <path id="path132" d="M 58.1,38.5 27.3,0 v 38.5 z" className="st0" />
  </Icon>
)

const colourYCss = `
	.st0{fill:#B8AC9C;}
  .st1{fill:#0C4577;}
  `
export const LogoColourY = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 342 339.29999"
    y="0px"
    x="0px"
    id="Layer_1"
    version="1.1"
  >
    <style id="style10" type="text/css">
      {colourYCss}
    </style>
    <g transform="translate(-250,-127)" id="g34">
      <path id="path12" d="m 250,466.3 h 342 v -6.7 H 250 Z" className="st0" />
      <path
        id="path14"
        d="m 415.6,427.6 c -2.6,0 -5,-0.2 -7.2,-0.6 -2.2,-0.5 -4.3,-1.2 -6.2,-2.1 -1.9,-1 -3.6,-2.2 -5.2,-3.6 l 2.2,-4.4 c 2.5,2.1 5,3.6 7.6,4.5 2.6,0.9 5.6,1.3 9,1.3 4,0 7.1,-0.8 9.3,-2.3 2.2,-1.6 3.3,-3.8 3.3,-6.7 0,-1.7 -0.6,-3.1 -1.7,-4.1 -1.1,-1.1 -2.6,-1.9 -4.5,-2.5 -1.9,-0.7 -4,-1.3 -6.4,-1.8 -2.4,-0.5 -4.6,-1.1 -6.8,-1.8 -2.1,-0.7 -4.1,-1.6 -5.7,-2.6 -1.6,-1 -2.9,-2.3 -3.9,-3.9 -0.9,-1.6 -1.4,-3.6 -1.4,-6 0,-2.8 0.7,-5.3 2.2,-7.5 1.5,-2.2 3.6,-3.9 6.3,-5.1 2.7,-1.2 5.9,-1.8 9.6,-1.8 2.3,0 4.5,0.3 6.6,0.8 2.1,0.5 4,1.2 5.8,2.1 1.8,0.9 3.4,2.1 4.8,3.4 l -2.2,4.4 c -2.3,-2 -4.7,-3.5 -7.1,-4.4 -2.4,-0.9 -5.1,-1.4 -7.9,-1.4 -3.9,0 -6.9,0.8 -9.1,2.5 -2.2,1.6 -3.3,3.9 -3.3,6.9 0,1.8 0.5,3.3 1.5,4.4 1,1.1 2.4,2 4.1,2.7 1.8,0.7 3.8,1.3 6.1,1.8 2.4,0.6 4.8,1.2 7,1.8 2.2,0.7 4.2,1.5 6,2.5 1.8,0.9 3.2,2.2 4.2,3.7 1,1.5 1.5,3.4 1.5,5.7 0,2.8 -0.7,5.3 -2.2,7.4 -1.5,2.1 -3.6,3.7 -6.4,4.9 -2.8,1.2 -6.1,1.8 -9.9,1.8 z"
        className="st1"
      />
      <path
        id="path16"
        d="m 456.3,427.5 c -3.1,0 -5.9,-0.7 -8.3,-2.2 -2.3,-1.5 -4.2,-3.6 -5.5,-6.3 -1.3,-2.8 -1.9,-6 -1.9,-9.7 0,-3.7 0.7,-6.9 2,-9.7 1.3,-2.8 3.1,-4.9 5.5,-6.4 2.4,-1.5 5.1,-2.3 8.2,-2.3 3.2,0 6,0.8 8.2,2.4 2.2,1.6 3.8,3.9 4.6,6.8 l -0.8,0.6 v -8.9 h 5.6 V 427 h -5.6 v -9.1 l 0.8,0.4 c -0.8,2.9 -2.3,5.2 -4.6,6.8 -2.3,1.6 -5,2.4 -8.2,2.4 z m 1.1,-4.7 c 3.5,0 6.1,-1.2 8,-3.5 1.9,-2.4 2.8,-5.7 2.8,-10.1 0,-4.3 -1,-7.7 -2.9,-10 -1.9,-2.3 -4.5,-3.5 -7.9,-3.5 -3.5,0 -6.2,1.2 -8.1,3.6 -1.9,2.4 -2.9,5.7 -2.9,10 0,4.3 1,7.6 2.9,9.9 1.9,2.5 4.6,3.6 8.1,3.6 z"
        className="st1"
      />
      <path
        id="path18"
        d="m 484.8,427 v -35.2 h 5.7 V 427 Z m -0.6,-50.1 h 6.9 v 6.4 h -6.9 z"
        className="st1"
      />
      <path id="path20" d="m 501.5,427 v -51.5 h 5.7 V 427 Z" className="st1" />
      <path
        id="path22"
        d="m 530.1,427.5 c -5.9,0 -10.6,-1.5 -14.1,-4.6 l 1.9,-4.2 c 1.9,1.4 3.8,2.5 5.7,3.2 2,0.7 4.2,1 6.7,1 2.7,0 4.7,-0.4 6,-1.3 1.4,-0.9 2.1,-2.3 2.1,-4 0,-1.4 -0.5,-2.5 -1.4,-3.3 -0.9,-0.8 -2.5,-1.5 -4.6,-2 l -6,-1.4 c -2.9,-0.7 -5.2,-1.8 -6.9,-3.5 -1.6,-1.7 -2.4,-3.7 -2.4,-6 0,-2.1 0.6,-3.9 1.7,-5.4 1.1,-1.6 2.7,-2.8 4.7,-3.7 2,-0.9 4.4,-1.3 7.2,-1.3 2.5,0 4.8,0.4 7,1.2 2.2,0.8 4,1.9 5.5,3.4 l -1.9,4.1 c -1.7,-1.4 -3.4,-2.4 -5.2,-3.1 -1.7,-0.7 -3.6,-1 -5.5,-1 -2.5,0 -4.5,0.5 -5.9,1.5 -1.4,1 -2,2.3 -2,4 0,1.4 0.4,2.6 1.3,3.4 0.9,0.8 2.3,1.5 4.2,2 l 6,1.5 c 3.3,0.7 5.7,1.9 7.3,3.5 1.6,1.6 2.4,3.6 2.4,6.1 0,3 -1.2,5.5 -3.7,7.3 -2.5,1.7 -5.9,2.6 -10.1,2.6 z"
        className="st1"
      />
      <path id="path24" d="M 445,260.6 338.2,127 v 133.6 z" className="st1" />
      <path
        id="path26"
        d="M 380.6,339.6 V 289 h 5.8 v 50.6 z m -35.7,0 V 289 h 5.7 v 50.6 z m 2.6,-23.2 v -4.9 h 36.3 v 4.9 z"
        className="st1"
      />
      <path
        id="path28"
        d="m 398.1,339.6 v -35.7 h 5.7 v 35.7 z m -0.6,-50.9 h 7 v 6.5 h -7 z"
        className="st1"
      />
      <path
        id="path30"
        d="m 430.4,355.4 c -5.4,0 -10,-1.2 -13.9,-3.7 l 1,-4.7 c 2.2,1.3 4.3,2.2 6.3,2.8 2,0.6 4.2,0.9 6.7,0.9 3.4,0 5.9,-0.9 7.7,-2.8 1.8,-1.8 2.7,-4.5 2.7,-8.1 v -9.7 l 0.8,-0.9 c -0.6,1.9 -1.5,3.6 -2.7,5 -1.2,1.4 -2.6,2.4 -4.4,3.2 -1.7,0.8 -3.7,1.1 -5.9,1.1 -3.1,0 -5.9,-0.7 -8.3,-2.2 -2.3,-1.5 -4.2,-3.6 -5.6,-6.3 -1.3,-2.7 -2,-5.8 -2,-9.3 0,-3.5 0.7,-6.7 2,-9.3 1.4,-2.7 3.2,-4.7 5.6,-6.2 2.4,-1.5 5.2,-2.3 8.3,-2.3 3.3,0 6.1,0.8 8.3,2.5 2.3,1.6 3.9,3.9 4.6,6.8 l -0.8,-0.8 v -7.7 h 5.7 v 35.5 c 0,5.4 -1.4,9.4 -4.1,12.1 -2.7,2.8 -6.7,4.1 -12,4.1 z m -0.7,-21.7 c 3.4,0 6.1,-1.1 8,-3.4 2,-2.3 3,-5.5 3,-9.5 0,-4 -1,-7.2 -3,-9.4 -1.9,-2.3 -4.6,-3.5 -8,-3.5 -3.5,0 -6.2,1.2 -8.2,3.5 -2,2.3 -3,5.4 -3,9.4 0,4.1 1,7.2 3,9.5 2,2.3 4.7,3.4 8.2,3.4 z"
        className="st1"
      />
      <path
        id="path32"
        d="m 457.4,339.6 v -52.3 h 5.7 v 23.8 l -0.9,0.7 c 1,-2.9 2.6,-5.1 4.9,-6.5 2.3,-1.5 5,-2.3 8,-2.3 8.5,0 12.7,4.7 12.7,14.1 v 22.4 h -5.7 v -22.2 c 0,-3.3 -0.7,-5.7 -2,-7.2 -1.3,-1.6 -3.4,-2.3 -6.2,-2.3 -3.3,0 -5.9,1 -7.9,3.1 -2,2 -3,4.8 -3,8.2 v 20.5 z"
        className="st1"
      />
    </g>
  </svg>
)

LogoWhiteY.propTypes = {
  className: PropTypes.string.isRequired,
}

LogoColourY.propTypes = {
  className: PropTypes.string.isRequired,
}
