import { Text } from "@chakra-ui/react"
import React from "react"
import { Boat } from "src/lib/types/boat"
import { Offer } from "src/lib/types/offer"
import Link from "./Link"

interface Props {
  boat: Boat
  offer?: Offer
}
const Covid = ({ boat, offer }: Props) => (
  <Text mx={4} textAlign="center">
    COVID 19: You can change your booking on most of our boats if your travel plans are affected by coronavirus.
    <br />
    <Link to="/covid-19/"> See here for more details</Link>
  </Text>
)

export default Covid
