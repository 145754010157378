import React from "react"
import { Box, Center } from "@chakra-ui/react"
import Calendar, { CalendarProps, CalendarTileProperties } from "react-calendar"
import dayjs from "dayjs"
import "react-calendar/dist/Calendar.css"
import { BiChevronLeft, BiChevronRight } from "react-icons/bi"

interface Props extends CalendarProps {
  selectedDays?: Date[]
}

export const BoatCalendar = ({ onChange, selectedDays, value, ...props }: Props) => {
  const getClassName = ({ date }: CalendarTileProperties) => {
    return selectedDays?.find((day) => dayjs(day).isSame(dayjs(date), "date"))
      ? "react-calendar__selectedDay"
      : date.getDay() === 6
      ? "react-calendar__saturday"
      : ""
  }
  return (
    <Box sx={{ ...getCalendarStyle() }}>
      <Calendar
        defaultActiveStartDate={new Date()}
        showFixedNumberOfWeeks={true}
        allowPartialRange={false}
        nextLabel={
          <Center>
            <Box boxSize="36px" as={BiChevronRight} _hover={{ bg: "gray.50" }} rounded="full" />
          </Center>
        }
        prevLabel={
          <Center>
            <Box boxSize="36px" as={BiChevronLeft} _hover={{ bg: "gray.50" }} rounded="full" />
          </Center>
        }
        minDate={new Date()}
        maxDate={dayjs().add(1, "year").add(1, "week").toDate()}
        onChange={onChange}
        tileClassName={getClassName}
        formatShortWeekday={(_, date) => dayjs(date).format("dd")}
        minDetail="month"
        {...props}
        value={value || null}
      />
    </Box>
  )
}

const blueAbbr = {
  color: "white !important",
  backgroundColor: "transparent !important",
  abbr: {
    display: "inline-block",
    height: "30px",
    width: "30px",
    paddingTop: "5px",
    top: "-5px",
    backgroundColor: "primary",
    borderRadius: "50%",
  },
}
const mutedBackground = {
  backgroundColor: "transparent !important",
  _before: {
    content: '""',
    height: "50px",
    width: "100%",
    position: "absolute",
    top: "-3px",
    left: 0,
    backgroundColor: "transparent !important",
    clipPath: "polygon(0 20%, 100% 20%, 100% 80%, 0 80%)",
  },
}

const rangeStart = {
  _before: {
    content: '""',
    height: "50px",
    position: "absolute",
    top: "-3px",
    left: 0,
    w: "100%",
    backgroundColor: "transparent !important",
    clipPath: "polygon(50% 20%, 100% 20%, 100% 80%, 50% 80%)",
  },
}

const rangeEnd = {
  _before: {
    content: '""',
    height: "50px",
    position: "absolute",
    top: "-3px",
    right: 0,
    w: "100%",
    backgroundColor: "transparent !important",
    clipPath: "polygon(50% 20%, 0% 20%, 0% 80%, 50% 80%)",
  },
}

const getCalendarStyle = () => ({
  margin: "0 auto",
  backgroundColor: "transparent !important",

  ".react-calendar": {
    border: "none",
    fontFamily: "body",
    fontSize: "1rem",
    backgroundColor: "transparent !important",
    position: "relative",
    mx: "auto",

    /* Navigation */

    ".react-calendar__navigation__label__labelText": {
      whiteSpace: "nowrap",
    },
    ".react-calendar__month-view__weekdays": {
      textTransform: "capitalize",
    },

    ".react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button": {
      display: "none",
    },

    ".react-calendar__navigation__label": {
      backgroundColor: "transparent !important",
      flex: 1,
      span: {
        fontSize: "0.9rem",
        color: "primary",
        fontWeight: "bold",
      },
    },

    ".react-calendar__navigation__arrow.react-calendar__navigation__prev-button, .react-calendar__navigation__arrow.react-calendar__navigation__next-button": {
      position: "relative",
      flex: 1,
      backgroundColor: "transparent",
    },

    ".react-calendar__month-view__weekdays__weekday": {
      fontFamily: "body",
      fontSize: "1rem",

      abbr: {
        textDecoration: "none",
      },
    },

    /* Tiles */

    "button.react-calendar__tile": {
      position: "relative",
      height: { base: "42px", md: "42px" },

      abbr: {
        position: "relative",
      },
    },
    ".react-calendar__tile--now": {
      backgroundColor: "transparent",
      abbr: {
        color: "gray.800",
        _hover: { color: "white" },
        backgroundColor: "transparent",
      },
    },
    ".react-calendar__tile--now:disabled": {
      abbr: {
        color: "gray.300",
        backgroundColor: "transparent",
        cursor: "auto",
        fontWeight: 400,
        _hover: { bg: "transparent", fontWeight: 400 },
        _before: {
          content: '""',
          backgroundColor: "transparent",
        },
      },
    },

    ".react-calendar__month-view__days__day--weekend": {
      color: "inherit",
    },
    ".react-calendar__month-view__days__day--neighboringMonth": {
      visibility: "hidden",
    },

    ".react-calendar__month-view__days__day": {
      color: "gray.800",
      fontWeight: "normal",
      _hover: {
        fontWeight: "bold",
      },
    },
    ".react-calendar__saturday": {
      color: "primary",
      fontWeight: "bold",
    },
    ".react-calendar__selectedDay": {
      ...blueAbbr,
    },

    /* Range Selected */

    ".react-calendar__tile--rangeEnd, .react-calendar__tile--rangeStart": {
      ...blueAbbr,
    },

    ".react-calendar__tile--active": {
      ...mutedBackground,
    },

    ".react-calendar__tile:disabled": {
      color: "gray.300",
      backgroundColor: "transparent",
      cursor: "auto",
      fontWeight: 400,
      _hover: { bg: "transparent", fontWeight: 400 },
      _before: {
        content: '""',
        backgroundColor: "transparent",
      },
    },
    ".react-calendar__tile:hover:not(:disabled), .react-calendar__tile--hasActive": {
      ...blueAbbr,
    },

    ".react-calendar__tile--active.react-calendar__tile--hover": {
      backgroundColor: "transparent",
    },
    ".react-calendar__tile--rangeEnd": {
      ...rangeEnd,
    },
    ".react-calendar__tile--rangeStart": {
      ...rangeStart,
    },
    ".react-calendar__tile--rangeBothEnds": {
      _before: {
        // display: "none",
      },
    },
  },

  /* Selecting Range */
  ".react-calendar--selectRange": {
    ".react-calendar__tile--hover": {
      ...mutedBackground,
    },
    ".react-calendar__tile--hoverEnd": {
      ...rangeEnd,
    },
    ".react-calendar__tile--hoverStart": {
      ...rangeStart,
    },
  },
})
