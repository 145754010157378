import { useStorageState } from "react-storage-hooks"

const dummyLocalStorage = {
  getItem: () => null,
  setItem: () => {},
  removeItem: () => {},
}

const dummySessionStorage = {
  getItem: () => null,
  setItem: () => {},
  removeItem: () => {},
}

const IS_BROWSER = typeof window !== "undefined"
// eslint-disable-next-line no-undef
const localStr = IS_BROWSER ? window.localStorage : dummyLocalStorage
// eslint-disable-next-line no-undef
const sessionStr = IS_BROWSER ? window.sessionStorage : dummySessionStorage

const useLocalStorageState = (...args) => useStorageState(localStr, ...args)

const useSessionStorageState = (...args) => useStorageState(sessionStr, ...args)

export { useLocalStorageState, useSessionStorageState }
