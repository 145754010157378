import React from "react"
import PropTypes from "prop-types"
import { RichText, Elements } from "prismic-reactjs"
import ReactMarkdown from "react-markdown"
import { Box, chakra, Heading, List, ListItem, Text } from "@chakra-ui/react"
import Link from "./Link"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { linkResolver } from "../gatsby/linkResolver"

const CImg = chakra(GatsbyImage)
const CReactMarkdown = chakra(ReactMarkdown)

const htmlSerializer = (type, element, content, children, key) => {
  switch (type) {
    case Elements.heading2:
      return (
        <Heading as="h2" variant="h2" key={key}>
          {children}
        </Heading>
      )
    case Elements.heading3:
      return (
        <Heading as="h3" variant="h3" mt={8} key={key}>
          {children}
        </Heading>
      )
    case Elements.heading4:
      return (
        <Heading as="h4" variant="h4" mt={8} key={key}>
          {children}
        </Heading>
      )
    case Elements.heading5:
      return (
        <Heading as="h5" variant="h5" mt={8} key={key}>
          {children}
        </Heading>
      )
    case Elements.heading6:
      return (
        <Heading as="h6" variant="h6" mt={8} key={key}>
          {children}
        </Heading>
      )
    case Elements.list:
      return <List key={key}>{children}</List>
    case Elements.listItem:
      return <ListItem key={key}>{children}</ListItem>
    case Elements.paragraph:
      return (
        <Text my={4} key={key}>
          {children}
        </Text>
      )
    case Elements.hyperlink:
      return (
        <Link key={element.data.id || element.data.url} to={linkResolver(element)}>
          {children}
        </Link>
      )
    default:
      return null
  }
}
const SliceZone = ({ allSlices }) => {
  const slices = allSlices.map((s) => {
    switch (s.slice_type) {
      case "rich_text":
        return <RichText key={s.id} render={s.primary.text.richText} htmlSerializer={htmlSerializer} Component={Box} />
      case "image":
        return (
          <Box key={s.id} sx={{ my: 4 }}>
            <CImg image={getImage(s.primary.image)} sx={{ maxHeight: "500px" }} alt={s.primary.image.alt} />
            {s.primary.caption && (
              <chakra.span
                sx={{
                  color: "muted",
                  fontSize: [0, 1],
                  mt: [2, 3],
                  display: "block",
                }}
              >
                {s.primary.caption}
              </chakra.span>
            )}
          </Box>
        )
      case "markdown":
        if (s.primary.table) {
          return (
            <CReactMarkdown
              key={s.id}
              sx={{
                table: {
                  width: "100%",
                  mx: "auto",
                  my: 4,
                  a: { variant: "links.primary" },
                  th: {
                    textAlign: "left",
                  },
                },
              }}
            >
              {RichText.asText(s.primary.table.richText)}
            </CReactMarkdown>
          )
        }
        return null
      default:
        return null
    }
  })
  return (
    <Box
      sx={{
        h2: { mt: 12, mb: 0 },
        h3: { mt: 12, fontSize: "2xl" },
        h4: { fontSize: "xl" },
        h5: { mb: 0, fontSize: "xl" },
        li: { mb: 2 },
      }}
    >
      {slices}
    </Box>
  )
}

SliceZone.propTypes = {
  allSlices: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SliceZone
