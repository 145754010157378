/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { CookiesProvider } from "react-cookie"
import { PrismicPreviewProvider, componentResolverFromMap } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "./src/gatsby/linkResolver"
import Article from "./src/templates/Article"

export const onClientEntry = async () => {
  if (document.location.href.includes("gclid")) {
    document.cookie = `originalLocation=${document.location.href}; path=/`
  }

  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}

export const wrapRootElement = ({ element }) => {
  const queryClient = new QueryClient()
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: "highsails",
          linkResolver: () => linkResolver,
          componentResolver: componentResolverFromMap({
            article: Article,
          }),
        },
      ]}
    >
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>{element}</CookiesProvider>
      </QueryClientProvider>
    </PrismicPreviewProvider>
  )
}
