import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "src/gatsby/linkResolver"
import PropTypes from "prop-types"
import { Box, Flex } from "@chakra-ui/react"

import website from "../../website"
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"
import SliceZone from "../components/SliceZone"
import { RequestOfferBanner } from "../components/RequestOfferBanner"

const Article = ({ data, location }) => {
  return (
    <Layout
      heroImage={data.prismicArticle.data.heroimage}
      heroCopy={data.prismicArticle.data.title}
      country={data.prismicArticle.data.country}
    >
      <SEO
        title={`${data.prismicArticle.data.title} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={data.description}
        node={data.prismicArticle}
        article
      />
      <Section as="article" mt={16}>
        <Flex
          sx={{
            width: ["100%", "90%"],
            mx: "auto",
          }}
          direction={{ base: "column", lg: "row" }}
        >
          <Box minW={{ base: "100%", lg: "250px" }} mr={8} display={{ base: "none", lg: "block" }}>
            <Box position="sticky" top={8}>
              <RequestOfferBanner small />
            </Box>
          </Box>

          <SliceZone allSlices={data.prismicArticle.data.body} />

          <Box mt={8} display={{ base: "block", lg: "none" }}>
            <RequestOfferBanner small />
          </Box>
        </Flex>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($id: String!) {
    prismicArticle(id: { eq: $id }) {
      _previewable
      data {
        articletype
        country
        location
        title
        heroimage {
          gatsbyImageData(height: 700)
        }
        meta_title
        meta_description
        meta_image {
          url
        }
        body {
          ... on PrismicArticleDataBodyRichText {
            id
            slice_type
            primary {
              text {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyImage {
            id
            slice_type
            primary {
              caption
              image {
                gatsbyImageData(width: 1280, height: 500)
              }
            }
          }
          ... on PrismicArticleDataBodyMarkdown {
            id
            slice_type
            primary {
              table {
                richText
              }
            }
          }
        }
      }
    }
  }
`

Article.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
}

export default withPrismicPreview(Article)
