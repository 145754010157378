import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { chakra, LinkProps } from "@chakra-ui/react"

const StyledLink = chakra(GatsbyLink)
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
interface Props extends LinkProps {
  children: React.ReactNode
  to: string
  className?: string
  activeClassName?: string
  partiallyActive?: boolean
}

const Link = ({ children, to, activeClassName, partiallyActive, className, ...other }: Props) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^(?!www|http)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <StyledLink
        to={to}
        activeClassName={activeClassName}
        className={className}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </StyledLink>
    )
  }
  return (
    <a href={to} className={className}>
      {children}
    </a>
  )
}

export default Link
