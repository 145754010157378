/* eslint-disable camelcase */
import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useCookies } from "react-cookie"
import { CloseButton, Flex } from "@chakra-ui/react"

import Covid from "./Covid"
import Footer from "./Footer"
import Header from "./Header"
import Hero from "./Hero"
import Link from "./Link"
import Section from "./Section"
import { ImageDataLike } from "gatsby-plugin-image"

const CookieBar = ({ inverted }: { inverted?: boolean }) => {
  const [cookies, setCookie] = useCookies(["cookie-bar"])
  if (cookies["cookie-bar"] === "closed") {
    return null
  }
  const invertedCSS = inverted
    ? {
        color: "white",
        backgroundColor: "primary",
        a: {
          color: "white",
          _hover: {
            textDecoration: "underline",
          },
        },
      }
    : {}
  return (
    <Section sx={{ ...invertedCSS }}>
      <Flex align="center" justify="center" fontSize="12px" py={0.75}>
        <span>
          HighSails uses cookies to make the site simpler.
          <Link ml={1} to="/privacy-policy">
            Find out more about cookies
          </Link>
        </span>
        <CloseButton
          size="sm"
          ml={4}
          sx={{
            color: inverted ? "white" : "muted",
          }}
          onClick={() => setCookie("cookie-bar", "closed", { path: "/" })}
        />
      </Flex>
    </Section>
  )
}

interface Props {
  children: React.ReactNode
  country?: string
  heroCopy?: string
  heroImage?: ImageDataLike
  invertedNav?: boolean
  noHero?: boolean
  hideHeroTabs?: boolean
  hideCovidBanner?: boolean
}

export const Layout = ({
  children,
  country,
  heroCopy,
  heroImage,
  invertedNav,
  noHero,
  hideHeroTabs,
  hideCovidBanner,
}: Props) => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (window.location.pathname === "/covid-19/") {
      // eslint-disable-next-line no-undef
      const cb = document.getElementsByClassName("covidbanner")[0]
      if (cb) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cb.style.display = "none"
      }
    }

    const button = document.querySelector("[id^=gb-widget]")
    if (button && window.location.pathname === "/") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      button.style.display = "none"
    } else if (button) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      button.style.display = "block"
    }
  }, [])
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      prismicHomepage {
        data {
          nav_1
          nav_2
        }
      }
    }
  `)

  const hasHero = heroCopy
  return (
    <>
      <CookieBar inverted={invertedNav} />
      <Header copy={data.prismicHomepage.data} invertedNav={invertedNav} />
      {hasHero && !noHero && (
        <>
          <Hero copy={heroCopy} image={heroImage} noHero country={country} hideHeroTabs={hideHeroTabs} />
          {!hideCovidBanner && <Covid className="covidbanner" sx={{ px: 6, textAlign: ["center"] }} />}
        </>
      )}
      {children}
      <Footer />
    </>
  )
}

export default Layout
