import React from "react"
import {
  Button,
  Stack,
  Text,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverFooter,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react"
import { BiMinus, BiPlus } from "react-icons/bi"
import { HiChevronDown } from "react-icons/hi"
import { OnChangeDateCallback } from "react-calendar"

import dayjs from "dayjs"

import { Boat, Calendar, Compass, Email } from "./Icons"
import { BoatCalendar } from "./BoatCalendar"

import { useToast } from "../lib/hooks/useToast"
import { BOAT_TYPES } from "../lib/static/boat"
import destinations from "../lib/static/destinations.json"
import { useLocalStorageState } from "src/gatsby/useSessionStorageState"

interface BoatType {
  label: string
  value: string
}
interface Destination {
  label: string
  value: string
  key?: string
  pre?: boolean
}

const allDestinations = (destinations.reduce((value: any, item: any) => {
  return [...value, item, ...(item.items || []).map((sub: any) => ({ ...sub, pre: true }))]
}, []) as unknown) as Destination[]

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const RequestOfferModal = (props: Props) => {
  const [guestCount, setGuestCount] = React.useState(4)
  const [boatType, setBoatType] = React.useState(BOAT_TYPES[0])
  const [checkinDate, setCheckinDate] = React.useState<string | undefined>(undefined)
  const [checkoutDate, setCheckoutDate] = React.useState<string | undefined>(undefined)
  const [destination, setDestination] = React.useState<Destination>()
  const [fullName, setFullName] = React.useState("")
  const [emailFromSession, setEmailFromSession] = useLocalStorageState("email")
  const [email, setEmail] = React.useState(emailFromSession || "")

  const toast = useToast()

  const onSubmit = async (e: any) => {
    e.preventDefault()

    try {
      const dcq = ((window as unknown) as { _dcq: any })._dcq
      if (dcq) {
        dcq.push(["identify", { email, full_name: fullName }])
        dcq.push([
          "track",
          "Request offer",
          {
            email,
            passengers: guestCount,
            checkin_date: checkinDate,
            checkout_date: checkoutDate,
            destination: destination?.value,
            boat_type: boatType?.value,
          },
        ])
      }

      setEmailFromSession(email)
      e.target.reset()
      props.onClose()
      toast({
        status: "success",
        description: "Thank you, we'll be in touch!",
      })
    } catch (err) {
      console.error(err)
      toast({
        status: "error",
        description: "There was an error submitting your request, please try again.",
      })
    }
  }

  return (
    <Modal {...props} size="lg">
      <ModalOverlay />
      <ModalContent>
        <Box as="form" onSubmit={onSubmit}>
          <ModalHeader textAlign="center" borderBottomWidth={1} borderColor="secondaryBg">
            <Text fontSize={17} lineHeight="30px" color="primary" mx={8}>
              Let us help you plan the perfect sailing trip
            </Text>

            <ModalCloseButton bgColor="secondaryBg" rounded="2xl" left={3} top={4} />
          </ModalHeader>

          <ModalBody>
            <Stack py={5} borderBottomWidth={1} borderColor="secondaryBg">
              <Text fontSize={14} lineHeight="22px" textAlign="center" color="primary">
                Provide us with some basic information and one of our experts will be in touch to help you plan the
                perfect sailing adventure
              </Text>
            </Stack>

            <Stack py={5} borderBottomWidth={1} borderColor="secondaryBg">
              <Text fontSize={16} fontWeight="bold" lineHeight="25px">
                Guests
              </Text>
              <SelectGuests count={guestCount} onSelect={(count: number) => setGuestCount(count)} />
            </Stack>
            <Stack py={5} borderBottomWidth={1} borderColor="secondaryBg">
              <Text fontSize={16} fontWeight="bold" lineHeight="25px">
                Travel Dates
              </Text>
              <SelectTravelDates
                checkinDate={checkinDate}
                checkoutDate={checkoutDate}
                onSelectCheckin={(date) => setCheckinDate(date)}
                onSelectCheckout={(date) => setCheckoutDate(date)}
              />
            </Stack>
            <Stack py={5} borderBottomWidth={1} borderColor="secondaryBg">
              <Text fontSize={16} fontWeight="bold" lineHeight="25px">
                Boat Types
              </Text>
              <SelectBoatType boatType={boatType} onSelect={(type: BoatType) => setBoatType(type)} />
            </Stack>
            <Stack py={5} borderBottomWidth={1} borderColor="secondaryBg">
              <Text fontSize={16} fontWeight="bold" lineHeight="25px">
                Destination
              </Text>
              <SelectDestination
                destination={destination}
                onSelect={(destination: Destination) => setDestination(destination)}
              />
            </Stack>
            <Stack py={5} borderBottomWidth={1} borderColor="secondaryBg">
              <Text name="fullName" fontSize={16} fontWeight="bold" lineHeight="25px">
                Full Name
              </Text>
              <Input
                placeholder="Type here"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                borderRadius="none"
              />
            </Stack>
            <Stack py={5}>
              <Text fontSize={16} fontWeight="bold" lineHeight="25px">
                E-Mail
              </Text>
              <FormControl isRequired>
                <InputGroup>
                  <InputLeftElement zIndex={0} pointerEvents="none" children={<Email boxSize={5} color="primary" />} />
                  <Input
                    placeholder="Email *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    borderRadius="none"
                  />
                </InputGroup>
                <FormErrorMessage>This field is required</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center" borderTopWidth={1} borderColor="secondaryBg">
            <Button size="lg" type="submit">
              REQUEST OFFER
            </Button>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  )
}

const SelectGuests = (props: { count: number; onSelect(arg: number): void }) => {
  const increment = () => {
    if (props.count === 20) return
    props.onSelect(props.count + 1)
  }
  const decrement = () => {
    if (props.count === 0) return
    props.onSelect(props.count - 1)
  }

  return (
    <HStack spacing={6}>
      <IconButton
        aria-label="decrement"
        isDisabled={props.count === 0}
        as={BiMinus}
        onClick={decrement}
        size="xs"
        borderRadius="full"
        variant="outline"
      />
      <Text color={props.count === 0 ? "muted" : "black"}>{props.count}</Text>
      <IconButton
        aria-label="increment"
        as={BiPlus}
        onClick={increment}
        size="xs"
        borderRadius="full"
        variant="outline"
      />
    </HStack>
  )
}

const SelectTravelDates = (props: {
  checkinDate: string | undefined
  checkoutDate: string | undefined
  onSelectCheckin(arg: string): void
  onSelectCheckout(arg: string): void
}) => {
  const checkinPopoverProps = useDisclosure()
  const checkoutPopoverProps = useDisclosure()

  const clearCheckinSelection = () => {
    checkinPopoverProps.onClose()
  }

  const clearCheckoutSelection = () => {
    checkoutPopoverProps.onClose()
  }

  const onChangeCheckin: OnChangeDateCallback = (val) => {
    let checkin
    if (Array.isArray(val)) {
      checkin = dayjs(val[0]).format("YYYY-MM-DD")
    } else {
      checkin = dayjs(val).format("YYYY-MM-DD")
    }
    props.onSelectCheckin(checkin)
    checkinPopoverProps.onClose()
  }
  const onChangeCheckout: OnChangeDateCallback = (val) => {
    let checkout
    if (Array.isArray(val)) {
      checkout = dayjs(val[0]).format("YYYY-MM-DD")
    } else {
      checkout = dayjs(val).format("YYYY-MM-DD")
    }
    props.onSelectCheckout(checkout)
    checkoutPopoverProps.onClose()
  }

  return (
    <Box>
      <Popover {...checkinPopoverProps} isLazy placement="bottom-start">
        <PopoverTrigger>
          <Button
            onClick={checkinPopoverProps.onOpen}
            fontWeight={400}
            variant="outline"
            leftIcon={<Calendar boxSize={5} color="primary" />}
            bg={checkinPopoverProps.isOpen ? "gray.50" : undefined}
            w="50%"
          >
            {!props.checkinDate ? "Check-in" : dayjs(props.checkinDate).format("MMM, D")}
          </Button>
        </PopoverTrigger>

        <PopoverContent w="min-content" bg="white" borderRadius={0}>
          <PopoverArrow />
          <PopoverBody pt={4}>
            <BoatCalendar
              showFixedNumberOfWeeks={true}
              onChange={onChangeCheckin}
              selectedDays={props.checkoutDate ? [new Date(props.checkoutDate)] : undefined}
              defaultActiveStartDate={props.checkinDate ? new Date(props.checkinDate) : undefined}
              value={props.checkinDate ? [new Date(props.checkinDate)] : null}
            />
          </PopoverBody>
          <PopoverFooter>
            <Flex align="center" justify="space-between">
              <Button variant="clear" onClick={clearCheckinSelection}>
                Clear
              </Button>
            </Flex>
          </PopoverFooter>
          <PopoverFooter bg="primary" color="white" fontSize="sm">
            Yacht charters typically start on Saturdays. In order to give you the best selection, book from Saturday to
            Saturday.
          </PopoverFooter>
        </PopoverContent>
      </Popover>
      <Popover {...checkoutPopoverProps} isLazy placement="bottom-end">
        <PopoverTrigger>
          <Button
            onClick={checkoutPopoverProps.onOpen}
            fontWeight={400}
            variant="outline"
            borderLeft={0}
            bg={checkoutPopoverProps.isOpen ? "gray.50" : undefined}
            w="50%"
          >
            {!props.checkoutDate ? "Check-out" : dayjs(props.checkoutDate).format("MMM, D")}
          </Button>
        </PopoverTrigger>

        <PopoverContent w="min-content" bg="white" borderRadius={0}>
          <PopoverArrow />
          <PopoverBody pt={4}>
            <BoatCalendar
              showFixedNumberOfWeeks={true}
              onChange={onChangeCheckout}
              tileDisabled={({ date }) => dayjs(date).isBefore(dayjs(props.checkinDate))}
              selectedDays={props.checkinDate ? [new Date(props.checkinDate)] : undefined}
              defaultActiveStartDate={
                props.checkoutDate
                  ? new Date(props.checkoutDate)
                  : props.checkinDate
                  ? new Date(props.checkinDate)
                  : new Date()
              }
              value={props.checkoutDate ? [new Date(props.checkoutDate)] : null}
            />
          </PopoverBody>
          <PopoverFooter>
            <Flex align="center" justify="space-between">
              <Button variant="clear" onClick={clearCheckoutSelection}>
                Clear
              </Button>
            </Flex>
          </PopoverFooter>
          <PopoverFooter bg="primary" color="white" fontSize="sm">
            Yacht charters typically end on Saturdays. In order to give you the best selection, book from Saturday to
            Saturday.
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

const SelectBoatType = (props: { boatType: BoatType; onSelect(arg: BoatType): void }) => {
  return (
    <Menu>
      <MenuButton as={Button} fontWeight={400} variant="outline" leftIcon={<Boat boxSize={5} color="primary" />}>
        {props.boatType?.label || "All Boat Types"}
      </MenuButton>
      <MenuList borderRadius={0} outline={0}>
        {BOAT_TYPES.map((type, i) => (
          <MenuItem
            key={type.value + i}
            bg={props.boatType?.value === type.value ? "gray.100" : "transparent"}
            onClick={() => props.onSelect(type)}
            py={2}
          >
            {type.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

const SelectDestination = (props: { destination: Destination | undefined; onSelect(arg: Destination): void }) => {
  return (
    <Box>
      <Menu>
        <MenuButton
          as={Button}
          fontWeight={400}
          variant="outline"
          leftIcon={<Compass boxSize={5} color="primary" />}
          w="100%"
        >
          {props.destination ? props.destination.label : "All destinations"}
        </MenuButton>
        <MenuList borderRadius={0} outline={0} h={400} overflow="scroll" pb={8} pos="relative">
          {allDestinations.map((destination, i) => (
            <MenuItem
              key={destination.value + i + destination.pre}
              bg={props.destination && props.destination.value === destination.value ? "gray.100" : "transparent"}
              onClick={() => props.onSelect(destination)}
              pl={destination.pre ? 8 : 4}
              pr={4}
              py={1}
            >
              {destination.label}
            </MenuItem>
          ))}
          <Flex pos="fixed" bottom={0} w="100%" bg="gray.50" align="center" justify="center">
            <Box as={HiChevronDown} boxSize="20px" />
          </Flex>
        </MenuList>
      </Menu>
    </Box>
  )
}
