import * as React from "react"
import { Button, Flex, Stack, Text } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { RequestOfferModal } from "./RequestOfferModal"

interface Props {
  small?: boolean
}

export const RequestOfferBanner = ({ small }: Props) => {
  const [showModal, setShowModal] = React.useState(false)
  if (small) {
    // Split into separate component?
    return (
      <>
        <Flex
          pos="relative"
          w={{ base: "100%", lg: "250px" }}
          direction="column"
          alignItems="center"
          justify="space-between"
          textAlign="center"
          p={6}
          bg="rgba(3, 3, 3, 0.9)"
          _before={{
            content: '""',
            bgSize: "cover",
            bgPos: "top",
            pos: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            opacity: 0.6,
          }}
        >
          <Stack pos="relative" spacing={8}>
            <Text fontSize={24} lineHeight="34px" color="white">
              Let us help you plan the perfect sailing trip
            </Text>
            <Text fontSize={16} lineHeight="24px" color="white">
              Provide your travel details, receive free offer and enjoy your holiday!
            </Text>
            <Button size="lg" variant="outline" bgColor="white" color="primary" onClick={() => setShowModal(true)}>
              Request Offer
            </Button>
          </Stack>
        </Flex>

        {showModal && <RequestOfferModal isOpen={showModal} onClose={() => setShowModal(false)} />}
      </>
    )
  }

  // Responsive styles
  return (
    <>
      <Flex
        pos="relative"
        direction={{ base: "column", md: "row" }}
        alignItems="center"
        justify="space-between"
        textAlign={{ base: "center", md: "left" }}
        my={8}
        p={12}
        bg="rgba(3, 3, 3, 0.9)"
        _before={{
          content: '""',
          bgSize: "cover",
          bgPos: { base: "top", md: "right" },
          pos: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          opacity: 0.6,
        }}
      >
        <Stack pos="relative" spacing={4}>
          <Text color="white" fontSize={{ base: 24, md: 22 }} lineHeight={{ base: "34px", md: "48px" }}>
            Let us help you plan the perfect sailing trip
          </Text>
          <Text color="white" fontSize={{ base: 16, md: 14 }} lineHeight={{ base: "24px", md: "18px" }}>
            Provide your travel details, receive free offer and enjoy your holiday!
          </Text>
        </Stack>
        <Button
          size="lg"
          mt={4}
          variant="outline"
          bgColor="white"
          colorScheme="blue"
          onClick={() => setShowModal(true)}
        >
          Request Offer
        </Button>
      </Flex>

      {showModal && <RequestOfferModal isOpen={showModal} onClose={() => setShowModal(false)} />}
    </>
  )
}
